import { useAccount, useConfig } from "wagmi";
import { getAccount } from "@wagmi/core";
import { getChainId } from "@/lib/util";

export default function useWalletConnect(): Boolean {
    // console.log("call useWalletConnect()");

    //* Check wallet connect.
    //* - Should connect to wallet.
    const { connector, isConnected, chain } = useAccount();
    // console.log("isConnected: ", isConnected);
    // console.log("chain: ", chain);

    // if (isConnected === false) {
    //   return false;
    // }

    //* Check network setting.
    //* - Should use the configured network.
    const { chains } = useConfig();
    // console.log("chains: ", chains);

    const configuredChainId = getChainId({
        chainName: process.env.NEXT_PUBLIC_BLOCKCHAIN_NETWORK,
    });
    // console.log("configuredChainId: ", configuredChainId);

    // console.log("chain?.id: ", chain?.id);
    // console.log("configuredChainId: ", configuredChainId);
    if (chain?.id !== configuredChainId) {
        return false;
    }

    //* Return wallet connect status.
    //* - Return true or false.
    return true;
}
