import { useState, useEffect } from "react";
import useSWR from "swr";
import { User } from "@/types/user";
import useWalletConnect from "@/lib/useWalletConnect";
import fetchJson, { FetchError } from "@/lib/fetchJson";

//* TODO: Call during 10 seconds
export default function useUser() {
    // console.log("call useUser()");

    const [isLoading, setIsLoading] = useState(true);

    //* Call useWalletConnect for checking the safe wallet connection.
    const walletConnectionStatus: Boolean = useWalletConnect();
    // console.log("walletConnectionStatus: ", walletConnectionStatus);

    const { data: user, mutate: mutateUser } = useSWR<User>({
        url: "/api/user",
    });
    // console.log("user:", JSON.stringify(user, null, 2));

    useEffect(() => {
        // console.log("call useEffect()");
        // console.log("user: ", user);
        // console.log("walletConnectionStatus: ", walletConnectionStatus);

        async function postUserLogout() {
            // console.log("call postUserLogout()");
            if (walletConnectionStatus === false && user?.isLoggedIn === true) {
                try {
                    await mutateUser(
                        await fetchJson(
                            { url: "/api/logout" },
                            { method: "POST" }
                        ),
                        false
                    );
                } catch (error) {
                    if (error instanceof FetchError) {
                        console.error(error.data.message);
                    } else {
                        console.error("An unexpected error happened:", error);
                    }
                }
            }

            setIsLoading(false);
        }

        postUserLogout();
    }, [user, walletConnectionStatus]);

    // console.log("user: ", user);
    return { user, mutateUser, isLoading };
}
