import React from "react";
import { useReadContract, useAccount, useWatchContractEvent } from "wagmi";
import { getContract } from "viem";
import useSWR from "swr";
import dynamic from "next/dynamic";
import Box from "@mui/material/Box";
import ListImage from "@/components/ListImage";
import ListNft from "@/components/ListNft";
import ListOwn from "@/components/ListOwn";
import ListRent from "@/components/ListRent";
import Settings from "@/components/Settings";
import fetchJson from "@/lib/fetchJson";
import promptNFTABI from "@/contracts/promptNFT.json";
import rentmarketABI from "@/contracts/rentMarket.json";
import ListFaucet from "./faucet/ListFaucet";
const DrawImage = dynamic(() => import("./DrawImage"), {
    ssr: false,
});
const LoginWrapper = dynamic(() => import("./LoginWrapper"), { ssr: false });

function List({ mode, updated, setNewImageCountFunc }) {
    // console.log("call List()");
    // console.log("mode: ", mode);
    // console.log("updated: ", updated);

    //*---------------------------------------------------------------------------
    //* Define constant variables.
    //*---------------------------------------------------------------------------
    const IMAGE_ALL_API_URL = "/api/all";

    //* Image refresh interval time by milli-second unit.
    const IMAGE_REFRESH_INTERVAL_TIME = 60000;

    const RENTMARKET_CONTRACT_ADDRES =
        process.env.NEXT_PUBLIC_RENTMARKET_CONTRACT_ADDRESS;
    // console.log("RENTMARKET_CONTRACT_ADDRES: ", RENTMARKET_CONTRACT_ADDRES);
    const PROMPTNFT_CONTRACT_ADDRESS =
        process.env.NEXT_PUBLIC_PROMPTNFT_CONTRACT_ADDRESS;

    //* After the image fetching, remove updated flag.
    const imageFetchFinished = React.useRef(false);

    //*---------------------------------------------------------------------------
    //* Wagmi.
    //*---------------------------------------------------------------------------
    const { address, isConnected } = useAccount();
    // console.log("isConnected: ", isConnected);
    // console.log("address: ", address);

    //* promptNftContract is used for useSWR params, so use useMemo.
    const promptNftContract = React.useMemo(
        () =>
            getContract({
                address: PROMPTNFT_CONTRACT_ADDRESS,
                abi: promptNFTABI["abi"],
            }),
        [PROMPTNFT_CONTRACT_ADDRESS, promptNFTABI["abi"]]
    );
    // console.log("promptNftContract: ", promptNftContract);

    //* Listen contract event.
    useWatchContractEvent({
        address: RENTMARKET_CONTRACT_ADDRES,
        abi: rentmarketABI["abi"],
        eventName: "RentNFT",
        listener(node, label, owner) {
            // console.log("node: ", node);
            // console.log("label: ", label);
            // console.log("owner: ", owner);
            swrRefetchRentData();
        },
    });

    useWatchContractEvent({
        address: RENTMARKET_CONTRACT_ADDRES,
        abi: rentmarketABI["abi"],
        eventName: "UnrentNFT",
        listener(node, label, owner) {
            // console.log("node: ", node);
            // console.log("label: ", label);
            // console.log("owner: ", owner);
            swrRefetchRentData();
        },
    });

    //* Change update flag middle function of image useSWR hook.
    function changeUpdateFlag(useSWRNext) {
        // console.log("call changeUpdateFlag()");

        return (key, fetcher, config) => {
            if (imageFetchFinished.current === true) {
                key = {
                    url: IMAGE_ALL_API_URL,
                };
            } else {
                key = {
                    url: `${IMAGE_ALL_API_URL}?updated=${updated}`,
                };
            }

            const swr = useSWRNext(key, fetcher, config);

            //* After the image fetching finished, remove updated flag.
            if (swr.isLoading !== true && swr.data) {
                imageFetchFinished.current = true;
            }

            return swr;
        };
    }

    const {
        data: dataImage,
        error: errorImage,
        isLoading: isLoadingImage,
        isValidating: isValidatingImage,
        mutate: mutateImage,
    } = useSWR(
        {
            url: `${IMAGE_ALL_API_URL}?updated=${updated}`,
        },
        fetchJson,
        {
            use: [changeUpdateFlag],
            refreshInterval: IMAGE_REFRESH_INTERVAL_TIME,
        }
    );
    // console.log("dataImage: ", dataImage);
    // console.log("isLoadingImage: ", isLoadingImage);
    // console.log("isValidatingImage: ", isValidatingImage);

    //* Get all register data array.
    const {
        data: swrDataRegisterData,
        isError: swrErrorRegisterData,
        isLoading: swrIsLoadingRegisterData,
        isValidating: swrIsValidatingRegisterData,
        status: swrStatusRegisterData,
    } = useReadContract({
        address: RENTMARKET_CONTRACT_ADDRES,
        abi: rentmarketABI.abi,
        functionName: "getAllRegisterData",
        // cacheOnBlock: true,
        // watch: true,
        onSuccess(data) {
            // console.log("call onSuccess()");
            // console.log("data: ", data);
        },
        onError(error) {
            // console.log("call onError()");
            // console.log("error: ", error);
        },
        onSettled(data, error) {
            // console.log("call onSettled()");
            // console.log("data: ", data);
            // console.log("error: ", error);
        },
    });

    //* Get all my rent data array.
    const {
        data: swrDataRentData,
        isError: swrErrorRentData,
        isLoading: swrIsLoadingRentData,
        isValidating: swrIsValidatingRentData,
        status: swrStatusRentData,
        refetch: swrRefetchRentData,
    } = useReadContract({
        address: RENTMARKET_CONTRACT_ADDRES,
        abi: rentmarketABI.abi,
        functionName: "getAllRentData",
        // cacheOnBlock: true,
        // watch: true,
        onSuccess(data) {
            // console.log("call onSuccess()");
            // console.log("data: ", data);
        },
        onError(error) {
            // console.log("call onError()");
            // console.log("error: ", error);
        },
        onSettled(data, error) {
            // console.log("call onSettled()");
            // console.log("data: ", data);
            // console.log("error: ", error);
        },
    });

    const {
        data: swrDataCollection,
        isError: swrErrorCollection,
        isLoading: swrIsLoadingCollection,
        isValidating: swrIsValidatingCollection,
        status: swrStatusCollection,
        refetch: swrRefetchCollection,
    } = useReadContract({
        address: RENTMARKET_CONTRACT_ADDRES,
        abi: rentmarketABI.abi,
        functionName: "getAllCollection",
        // cacheOnBlock: true,
        // watch: true,
    });

    //* Get all my own data array.
    const {
        data: dataAllMyOwnData,
        error: errorAllMyOwnData,
        isLoading: isLoadingAllMyOwnData,
        isValidating: isValidatingAllMyOwnData,
    } = useSWR(
        {
            command: "getAllMyOwnData",
            promptNftContract: promptNftContract,
            ownerAddress: address,
        },
        fetchJson,
        {
            refreshInterval: IMAGE_REFRESH_INTERVAL_TIME,
        }
    );
    // console.log("dataAllMyOwnData: ", dataAllMyOwnData);

    //*---------------------------------------------------------------------------
    //* Define state data.
    //*---------------------------------------------------------------------------
    const [allNftDataArray, setAllNftDataArray] = React.useState();
    const [allOwnDataArray, setAllOwnDataArray] = React.useState();
    const [allMyRentDataArray, setAllMyRentDataArray] = React.useState();

    React.useEffect(() => {
        // console.log("call useEffect()");
        // console.log("dataImage: ", dataImage);
        // console.log(
        //   "dataImage?.newlyUpdatedData?.length: ",
        //   dataImage?.newlyUpdatedData?.length
        // );

        if ((dataImage?.newlyUpdatedData?.length || 0) > 0) {
            setNewImageCountFunc({
                newImageCount: dataImage.newlyUpdatedData.length,
            });
        }

        initialize();
    }, [dataAllMyOwnData]);

    function initialize() {
        // console.log("call initialize()");
        // console.log("swrDataRegisterData: ", swrDataRegisterData);
        // console.log("swrErrorRegisterData: ", swrErrorRegisterData);
        // console.log("swrIsLoadingRegisterData: ", swrIsLoadingRegisterData);
        // console.log("swrIsValidatingRegisterData: ", swrIsValidatingRegisterData);
        // console.log("swrStatusRegisterData: ", swrStatusRegisterData);
        // console.log("swrDataCollection: ", swrDataCollection);
        // console.log("dataAllMyOwnData: ", dataAllMyOwnData);
        // console.log("dataRent: ", dataRent);

        //* Find the register data in registered collection.
        //* After registering data, even though collection is removed, register data remains.
        let registerData;
        if (swrDataRegisterData && swrDataCollection) {
            registerData = swrDataRegisterData.filter(function (registerData) {
                return swrDataCollection.some(function (collection) {
                    return (
                        collection.collectionAddress.toLowerCase() ===
                        registerData.nftAddress.toLowerCase()
                    );
                });
            });
        }
        // console.log("registerData: ", registerData);

        //* Find the own nft from registered nft data.
        // console.log("PROMPTNFT_CONTRACT_ADDRESS: ", PROMPTNFT_CONTRACT_ADDRESS);
        let ownDataArray;
        if (registerData && dataAllMyOwnData) {
            ownDataArray = registerData.filter(function (nft) {
                // console.log("nft: ", nft);
                // console.log("nft.tokenId: ", nft.tokenId);
                return dataAllMyOwnData.some(function (element) {
                    // console.log("element: ", element);
                    return (
                        nft.tokenId === element.tokenId &&
                        nft.nftAddress.localeCompare(
                            PROMPTNFT_CONTRACT_ADDRESS,
                            undefined,
                            {
                                sensitivity: "accent",
                            }
                        ) === 0
                    );
                });
            });
            // console.log("ownDataArray: ", ownDataArray);
            setAllOwnDataArray(ownDataArray.reverse());
        }

        //* Set all rent data.
        let allMyRentDataArray;
        // console.log("swrDataRentData: ", swrDataRentData);
        if (swrDataRentData) {
            // console.log("address: ", address);
            allMyRentDataArray = swrDataRentData.filter(
                (rentData) =>
                    rentData.renteeAddress.localeCompare(address, undefined, {
                        sensitivity: "accent",
                    }) === 0
            );
            // console.log("allMyRentDataArray: ", allMyRentDataArray);
            setAllMyRentDataArray(allMyRentDataArray);
        }

        //* Set all registered nft data.
        if (registerData) {
            // console.log("registerData: ", registerData);
            const dataNftWithStatusArray = registerData.map(function (nft) {
                let isOwn = false;
                let isRent = false;

                //* Check own status.
                if (ownDataArray) {
                    const someResult = ownDataArray.some(function (ownData) {
                        return (
                            ownData.tokenId === nft.tokenId &&
                            ownData.nftAddress.localeCompare(
                                PROMPTNFT_CONTRACT_ADDRESS,
                                undefined,
                                {
                                    sensitivity: "accent",
                                }
                            ) === 0
                        );
                    });
                    if (someResult === true) {
                        isOwn = true;
                    } else {
                        isOwn = false;
                    }
                }

                //* Check rent status.
                if (allMyRentDataArray) {
                    const someResult = allMyRentDataArray.some(function (
                        rentData
                    ) {
                        // console.log("rentData: ", rentData);
                        return (
                            rentData.tokenId === nft.tokenId &&
                            rentData.renteeAddress.localeCompare(
                                address,
                                undefined,
                                {
                                    sensitivity: "accent",
                                }
                            ) === 0
                        );
                    });

                    if (someResult === true) {
                        isRent = true;
                    } else {
                        isRent = false;
                    }
                }

                // console.log("nft.tokenId: ", nft.tokenId.toNumber());
                // console.log("isOwn: ", isOwn);
                // console.log("isRent: ", isRent);

                return {
                    ...nft,
                    isOwn: isOwn,
                    isRent: isRent,
                };
            });

            // console.log("dataNftWithStatusArray: ", dataNftWithStatusArray);
            setAllNftDataArray(dataNftWithStatusArray.reverse());
        }
    }

    // console.log(mode);

    return (
        <div>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                {mode === "draw" ? (
                    <LoginWrapper>
                        <DrawImage />
                    </LoginWrapper>
                ) : mode === "image" ? (
                    <ListImage />
                ) : mode === "nft" ? (
                    <LoginWrapper>
                        <ListNft />
                    </LoginWrapper>
                ) : mode === "own" ? (
                    <LoginWrapper>
                        <ListOwn
                            data={allOwnDataArray}
                            isLoading={isLoadingAllMyOwnData}
                        />
                    </LoginWrapper>
                ) : mode === "rent" ? (
                    <LoginWrapper>
                        <ListRent
                            data={allMyRentDataArray}
                            isLoading={swrIsLoadingRentData}
                        />
                    </LoginWrapper>
                ) : mode === "settings" ? (
                    <div>
                        <Settings />
                    </div>
                ) : mode === "faucet" ? (
                    <LoginWrapper>
                        <ListFaucet />
                    </LoginWrapper>
                ) : null}
            </Box>
        </div>
    );
}

export default List;
