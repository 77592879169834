import { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { WagmiProvider, createConfig, http } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { polygon, polygonAmoy, localhost } from "wagmi/chains";
import { injected, metaMask } from "wagmi/connectors";
import { SWRConfig } from "swr";
import { RecoilRoot } from "recoil";
import { GoogleAnalytics } from "nextjs-google-analytics";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import "@/styles/globals.css";
import { themeOptions } from "@/utils/themeOptions";
import createEmotionCache from "@/utils/createEmotionCache";
import fetchJson from "@/lib/fetchJson";
import * as gtag from "@/lib/gtag";

// Add emotion cache.
const clientSideEmotionCache = createEmotionCache();

// Add more properties.
export default function MyApp({
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
}) {
    const theme = createTheme(themeOptions);

    const queryClient = new QueryClient();
    const wagmiConfig = createConfig({
        chains: [localhost, polygonAmoy, polygon],
        transports: {
            [localhost.id]: http(),
            [polygonAmoy.id]: http(),
            [polygon.id]: http(),
        },
        connectors: [metaMask()],
    });

    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url) => {
            gtag.pageview(url);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return (
        <SWRConfig
            value={{
                fetcher: fetchJson,
                revalidateOnFocus: false,
                keepPreviousData: false,
                onError: (error) => {
                    console.error(error);
                },
            }}
        >
            <CacheProvider value={emotionCache}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <WagmiProvider config={wagmiConfig}>
                        <QueryClientProvider client={queryClient}>
                            <RecoilRoot>
                                <GoogleAnalytics trackPageViews />
                                <Head>
                                    <script
                                        dangerouslySetInnerHTML={{
                                            __html: `
                              window.dataLayer = window.dataLayer || [];
                              function gtag(){dataLayer.push(arguments);}
                              gtag('js', new Date());

                              gtag('config', '${gtag.GA_TRACKING_ID}', {
                                page_path: window.location.pathname,
                              });
                            `,
                                        }}
                                    />
                                </Head>
                                {/* Global Site Tag (gtag.js) - Google Analytics */}
                                <Script
                                    strategy="afterInteractive"
                                    src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
                                />
                                <Component {...pageProps} />
                            </RecoilRoot>
                        </QueryClientProvider>
                    </WagmiProvider>
                </ThemeProvider>
            </CacheProvider>
        </SWRConfig>
    );
}
